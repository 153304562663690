<template>
  <KTCard :page="page">
    <template #toolbar>
      <KTViewButton :to="addRoute" v-if="finalizado" />
      <KTEditButton :to="addRoute" v-else />
    </template>
    <template #body>
      <KTTable
        :fields="produtos_columns"
        :items="produtos.data"
        :primaryKey="produtos.primaryKey"
        :sortBy="produtos.sortBy"
      />
    </template>
  </KTCard>
</template>

<script>
import ApiMixin from "@/core/mixins/api.mixin.js";
import HelpersMixin from "@/core/mixins/helpers.mixin.js";

export default {
  name: "PipCustoMateriaisList",
  props: {
    idplano_investimento: {
      required: true
    }
  },
  mixins: [ApiMixin, HelpersMixin],
  data() {
    return {
      page: {
        icon: "la la-cart-plus",
        title: "Custo com Materiais",
        description: `Aqui você pode verificar o custo total com materiais de cada
          produto que será fabricado. Através do botão Editar, efetue o reajuste de
          preço de cada item, conforme considerar necessário.`
      },
      produtos: {
        data: [],
        primaryKey: "idpip_produto",
        sortBy: "numero_item"
      },
      anos: 0,
      tipo_plano_investimento: null,
      finalizado: true
    };
  },
  computed: {
    apiResource() {
      return `pip/${this.idplano_investimento}/custo_materiais`;
    },
    manual() {
      return {
        session: "custo_materiais",
        tipo: this.tipo_plano_investimento
      };
    },
    parentRoute() {
      return {
        name: "pip_ver",
        params: { idplano_investimento: this.idplano_investimento }
      };
    },
    addRoute() {
      return {
        name: "pip_custo_materiais_new",
        params: { idplano_investimento: this.idplano_investimento }
      };
    },
    produtos_columns() {
      let columns = [
        {
          key: "numero_item",
          label: "Item",
          sortable: true
        },
        {
          key: "descricao",
          label: "Descrição do Produto",
          sortable: true
        },
        {
          key: "nome_unidade_medida",
          label: "Unidade de Medida",
          sortable: true,
          tdClass: "text-center"
        },
        {
          key: "simbolo_moeda",
          label: "Moeda",
          sortable: true,
          tdClass: "text-center"
        }
      ];

      for (let ano = 1; ano <= this.anos; ano++) {
        columns.push({
          key: "custo_unitario_materiais_ano_" + ano,
          label: "Custo Unitário Ano " + ano,
          sortable: true,
          tdClass: "text-right",
          formatter: "currency"
        });
        columns.push({
          key: "valor_total_ano_" + ano,
          label: "Custo Total Ano " + ano,
          sortable: true,
          tdClass: "text-right",
          formatter: "currency"
        });
      }

      return columns;
    }
  },
  methods: {
    getPageData() {
      const requests = [this.Api.GetList()];
      const resolveFuntion = (res) => {
        // Requests index's
        const R_GETLIST = 0;

        this.produtos.data = res[R_GETLIST].produtos;
        this.anos = parseInt(res[R_GETLIST].anos);
        this.tipo_plano_investimento = res[R_GETLIST].tipo_plano_investimento;
        this.finalizado = res[R_GETLIST].finalizado;

        this.initManual(this.manual.session, this.manual.tipo);
      };

      this.ApiGetPageData(requests, resolveFuntion);
    }
  },
  mounted() {
    this.getPageData();
  }
};
</script>
